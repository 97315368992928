exports.components = {
  "component---src-components-redirect-germany-js": () => import("./../../../src/components/redirectGermany.js" /* webpackChunkName: "component---src-components-redirect-germany-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-services-js": () => import("./../../../src/pages/book-services.js" /* webpackChunkName: "component---src-pages-book-services-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-distribution-book-printing-cost-and-royalties-calculator-js": () => import("./../../../src/pages/distribution/book-printing-cost-and-royalties-calculator.js" /* webpackChunkName: "component---src-pages-distribution-book-printing-cost-and-royalties-calculator-js" */),
  "component---src-pages-distribution-direct-marketing-js": () => import("./../../../src/pages/distribution/direct-marketing.js" /* webpackChunkName: "component---src-pages-distribution-direct-marketing-js" */),
  "component---src-pages-distribution-js": () => import("./../../../src/pages/distribution.js" /* webpackChunkName: "component---src-pages-distribution-js" */),
  "component---src-pages-distribution-mobile-app-js": () => import("./../../../src/pages/distribution/mobile-app.js" /* webpackChunkName: "component---src-pages-distribution-mobile-app-js" */),
  "component---src-pages-distribution-partners-js": () => import("./../../../src/pages/distribution-partners.js" /* webpackChunkName: "component---src-pages-distribution-partners-js" */),
  "component---src-pages-distribution-publishers-js": () => import("./../../../src/pages/distribution/publishers.js" /* webpackChunkName: "component---src-pages-distribution-publishers-js" */),
  "component---src-pages-distribution-retail-partners-js": () => import("./../../../src/pages/distribution/retail-partners.js" /* webpackChunkName: "component---src-pages-distribution-retail-partners-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legalpolicy-js": () => import("./../../../src/pages/legalpolicy.js" /* webpackChunkName: "component---src-pages-legalpolicy-js" */),
  "component---src-pages-markets-audiobooks-js": () => import("./../../../src/pages/markets/audiobooks.js" /* webpackChunkName: "component---src-pages-markets-audiobooks-js" */),
  "component---src-pages-markets-ebooks-js": () => import("./../../../src/pages/markets/ebooks.js" /* webpackChunkName: "component---src-pages-markets-ebooks-js" */),
  "component---src-pages-markets-print-on-demand-js": () => import("./../../../src/pages/markets/print-on-demand.js" /* webpackChunkName: "component---src-pages-markets-print-on-demand-js" */),
  "component---src-pages-newsletter-archive-js": () => import("./../../../src/pages/newsletter/archive.js" /* webpackChunkName: "component---src-pages-newsletter-archive-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publishers-js": () => import("./../../../src/pages/publishers.js" /* webpackChunkName: "component---src-pages-publishers-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

