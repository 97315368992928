module.exports = [{
      plugin: require('../plugins/gatsby-source-streetlib-data/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-static-pages/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src4183290488/src/streetlib-main-website/src/data/translations","languages":["en","it","pt"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"StreetLib","short_name":"streetlib","start_url":"/","background_color":"#148EA0","theme_color":"#148EA0","display":"minimal-ui","icon":"/codebuild/output/src4183290488/src/streetlib-main-website/src/assets/image/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1570cab0627f698e282e857ba0e8cd90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-Q44JWV6PS5","cookieName":"gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"247813679951838","cookieName":"gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
